// Imports => Dependencies
import React, { createContext } from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';

// Imports => API
import createApi from '@api';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcGetState, AcSaveState, AcRemoveState } from '@utils';

// Imports => Stores
import AuthStore from '@stores/auth.store';
import CompaniesStore from '@stores/companies.store';

import ConfigurationsStore from '@stores/configurations.store';

import ContractsStore from '@stores/contracts.store';

import ControlUnitTypesStore from '@stores/control-unit-types.store';
import ControlUnitsStore from '@stores/control-units.store';

import EquipmentStore from '@stores/equipment.store';

import ErpStore from '@stores/erp.store';

import HydrohammerTypesStore from '@stores/hydrohammer-types.store';
import HydrohammersStore from '@stores/hydrohammers.store';

import JeanLutzStore from '@stores/jean-lutz.store';

import MyCompanyStore from '@stores/my-company.store';

import PermissionsStore from '@stores/permissions.store';

import PileListStore from '@stores/pilelist.store';

import PowerpackTypesStore from '@stores/powerpack-types.store';
import PowerpacksStore from '@stores/powerpacks.store';

import ProfileStore from '@stores/profile.store';

import ProjectsStore from '@stores/projects.store';

import PulseTypesStore from '@stores/pulse-types.store';
import PulsesStore from '@stores/pulses.store';

import ReportsStore from '@stores/reports.store';

import SleeveTypesStore from '@stores/sleeve-types.store';
import SleevesStore from '@stores/sleeves.store';

import ToastersStore from '@stores/toasters.store';

import TransfersStore from '@stores/transfers.store';

import UiStore from '@stores/ui.store';

import UsersStore from '@stores/users.store';

import VibratoryHammerTypesStore from '@stores/vibratory-hammer-types.store';
import VibratoryHammersStore from '@stores/vibratory-hammers.store';

import { OperationCenterStore } from './operationcenter.store';

class Store {
  constructor(config) {
    makeObservable(this);

    this.config = config;

    this.api = createApi(config);

    this.alerts = new OperationCenterStore(this);
    this.auth = new AuthStore(this);
    this.companies = new CompaniesStore(this);
    this.configurations = new ConfigurationsStore(this);
    this.contracts = new ContractsStore(this);
    this.control_unit_types = new ControlUnitTypesStore(this);
    this.control_units = new ControlUnitsStore(this);
    this.equipment = new EquipmentStore(this);
    this.erp = new ErpStore(this);
    this.hydrohammer_types = new HydrohammerTypesStore(this);
    this.hydrohammers = new HydrohammersStore(this);
    this.jeanlutz = new JeanLutzStore(this);
    this.myCompany = new MyCompanyStore(this);
    this.permissions = new PermissionsStore(this);
    this.pilelist = new PileListStore(this);
    this.powerpack_types = new PowerpackTypesStore(this);
    this.powerpacks = new PowerpacksStore(this);
    this.profile = new ProfileStore(this);
    this.projects = new ProjectsStore(this);
    this.pulse_types = new PulseTypesStore(this);
    this.pulses = new PulsesStore(this);
    this.reports = new ReportsStore(this);
    this.sleeve_types = new SleeveTypesStore(this);
    this.sleeves = new SleevesStore(this);
    this.toasters = new ToastersStore(this);
    this.transfers = new TransfersStore(this);
    this.ui = new UiStore(this);
    this.users = new UsersStore(this);
    this.vibratory_hammer_types = new VibratoryHammerTypesStore(this);
    this.vibratory_hammers = new VibratoryHammersStore(this);

    window.addEventListener(
      'swFreshContentReady',
      this.handleSWFreshContentReady,
      true
    );
    window.addEventListener('swCacheReady', this.handleSWCacheReady, true);
  }

  @action
  resetStores = () => {
    return new Promise((resolve) => {
      this.companies.resetParams();
      this.configurations.resetParams();
      this.contracts.resetParams();
      this.control_unit_types.resetParams();
      this.control_units.resetParams();
      this.hydrohammer_types.resetParams();
      this.hydrohammers.resetParams();
      this.vibratory_hammer_types.resetParams();
      this.vibratory_hammers.resetParams();
      this.jeanlutz.resetParams();
      this.powerpack_types.resetParams();
      this.powerpacks.resetParams();
      this.projects.resetParams();
      this.pulse_types.resetParams();
      this.pulses.resetParams();
      this.sleeve_types.resetParams();
      this.sleeves.resetParams();
      this.users.resetParams();
      this.erp.resetParams();
      resolve();
    });
  };

  @observable
  freshContentReady = false;

  @computed
  get freshContentIsAvailable() {
    return this.freshContentReady;
  }

  @action
  handleSWFreshContentReady = (event) => {
    console.group('handleSWFreshContentReady');
    console.log('Event', event);
    console.groupEnd();

    runInAction(() => {
      this.freshContentReady = true;
    });
  };

  @action
  handleSWCacheReady = (event) => {
    console.group('handleSWCacheReady');
    console.log('Event', event);
    console.groupEnd();

    this.toasters.add({
      variant: 'info',
      title: 'Cache is ready',
      description: 'Resources and content are cached for offline use.',
    });
  };

  @observable
  debug_mode = AcGetState(KEYS.DEBUG) === 1 ? true : false;

  @action
  handleActivateDebugMode = () => {
    this.debug_mode = true;
    AcSaveState(KEYS.DEBUG, 1);
  };

  @action
  handleDeactivateDebugMode = () => {
    this.debug_mode = false;
    AcRemoveState(KEYS.DEBUG);
  };
}

export default Store;

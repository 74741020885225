// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
} from '@utils';

const _default = {
  options: {},
  roles: null,
  users: null,
  user: null,
};

let app = {};

export class UsersStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.USERS);
    // AcAutoLoad(this, KEYS.USER);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  roles = null;

  @observable
  users = null;

  @observable
  user = null;

  @observable
  onlyIqip = false;

  @observable
  onlyCustomers = false;

  @computed
  get current_users() {
    return this.users;
  }

  @computed
  get current_users_list() {
    if (!this.current_users || !this.current_users.data) return [];

    const { is_iqip_user } = app.store.profile?.current_profile;

    let opts = {
      ID: 'users.id',
      NAME: 'users.name',
      EMAIL_ADDRESS: 'email',
    };

    if (is_iqip_user) {
      opts.COMPANY = 'companies';
    }

    const result = new AcFormatRawDataAsList(this.current_users.data, opts);

    return result;
  }

  @computed
  get current_user() {
    return this.user;
  }

  @computed
  get current_roles() {
    return this.roles || [];
  }

  @computed
  get current_query() {
    return this.query;
  }

  @computed
  get current_roles_query() {
    return this.roles_query;
  }

  @computed
  get current_company_query() {
    return this.company_query;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @observable
  roles_query = [];

  @observable
  company_query = [];

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.current_query !== _input) this.query = _input;
  };

  @action
  setRoleQuery = (input) => {
    this.roles_query = input;
  };

  @action
  setCompanyQuery = (input) => {
    this.company_query = input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
    this.onlyIqip = 0;
    this.onlyCustomers = 0;
    this.roles_query = [];
    this.company_query = [];
    this.resetRoles();
  };

  resetRoles = () => {
    this.roles_query = [];
  };

  resetCompany = () => {
    this.company_query = [];
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  setOnlyCustomers = (bool = true) => {
    if (bool === true) {
      this.onlyIqip = 0;
    }

    this.onlyCustomers = +bool;
  };

  @action
  setOnlyIqip = (bool = true) => {
    if (bool === true) {
      this.onlyCustomers = 0;
    }

    this.onlyIqip = +bool;
  };

  @action
  list = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.users
      .list(params)
      .then((response) => {
        this.set(KEYS.USERS, response, false);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve users',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.users
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.USER, response, false);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve user with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_roles = (params = {}) => {
    this.setBusy(true);

    return app.store.api.users
      .get_roles(params)
      .then((response) => {
        this.set(KEYS.ROLES, response, false);

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve assignable roles'`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  verify_by_email = ({ email }) => {
    this.setBusy(true);

    return app.store.api.users
      .verify_by_email({ email })
      .then((response) => {
        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to verify the provided email address '<strong>${email}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  add_user_to_company = (id, { companies }) => {
    this.setBusy(true);

    return app.store.api.users
      .add_user_to_company(id, { companies: [companies] })
      .then((response) => {
        app.store.toasters.add({
          variant: 'success',
          description: `User has been assigned to company.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to add the user to the company with id '<strong>${companies}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    return app.store.api.users
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.USER, response, false);

        app.store.toasters.add({
          variant: 'success',
          title: `User <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store user`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (data) => {
    this.setBusy(true);

    const { id, name } = data;

    return app.store.api.users
      .update(id, data)
      .then(async (response) => {
        await this.set(KEYS.USER, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: `User details saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update user <strong>${name}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  patch = (data) => {
    this.setBusy(true);

    const { id, name } = data;

    return app.store.api.users
      .patch(id, data)
      .then(async (response) => {
        await this.set(KEYS.USER, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: `User details saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update user <strong>${name}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.users
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `User <strong>${name}</strong> deleted`,
        });

        await this.list();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete user <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default UsersStore;

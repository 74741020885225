// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, toJS, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS, FILE_TYPES } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcDownloadFile,
} from '@utils';

const _default = {
  options: {},
  pilelists: null,
  pilelist: null,
  transfers: null,
};

let app = {};
let polling_interval = null;

export class TransfersStore {
  constructor(store) {
    makeObservable(this);

    app.store = store;
  }

  @observable
  transfers = _default.transfers;

  @computed
  get current_transfers() {
    return AcIsSet(this.transfers) ? toJS(this.transfers) : null;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (project_id, options, withLoader) => {
    if (withLoader) {
      this.setLoading(true);
      this.setBusy(true);
    }

    let _project_id = project_id;

    if (!AcIsSet(_project_id)) {
      _project_id = app.store.projects?.current_project?.id;
    }

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.transfers
      .list(_project_id, params)
      .then((response) => {
        this.set(KEYS.TRANSFERS, response, false);

        // Check if we have pending items
        const pending = response?.data.find(
          (n) => n.status === 'pending' || n.status === 'new'
        );
        if (AcIsSet(pending)) {
          this.start_polling_transfers(_project_id);
        } else {
          this.stop_polling_transfers();
        }

        this.setLoading(false);
        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve transfer history',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);
        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  start_polling_transfers = (project_id) => {
    if (polling_interval) clearTimeout(polling_interval);

    polling_interval = setTimeout(() => {
      this.list(project_id, null, false);
    }, 60 * 1000);
  };

  @action
  stop_polling_transfers = () => {
    clearTimeout(polling_interval);
  };

  @action
  download_xls = async (project_id, transfer_id, params) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `XLS download has been requested`,
      indeterminate: true,
    });

    return app.store.api.transfers
      .download_xls(project_id, transfer_id, params)
      .then(async (response) => {
        const { headers, data } = response;
        const { url, name } = data.data;

        if (window?.open) {
          window.open(url, name, null);
        }

        app.store.toasters.update(toast.id, {
          title: 'Download started',
          description: `XLS has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download XLS`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download_control_unit_file = async (project_id, transfer_id, params) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `Control unit file download has been requested`,
      indeterminate: true,
    });

    return app.store.api.transfers
      .download_control_unit_file(project_id, transfer_id, params)
      .then(async (response) => {
        const { headers, data } = response;
        const { url, name } = data.data;

        if (window?.open) {
          window.open(url, name, null);
        }

        app.store.toasters.update(toast.id, {
          title: 'Download started',
          description: `Control unit file has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download Control unit file`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default TransfersStore;

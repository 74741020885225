// Imports => MOBX
import {
  makeObservable,
  observable,
  computed,
  action,
  toJS,
  set,
  runInAction,
} from 'mobx';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsUndefined } from '@utils';

const _default = {
  modal: {
    id: AcUUID(),
    visible: false,
    subTitle: '',
    title: '',
    body: [],
    progress: null,
    actions: [],
    closeable: true,
    forceRerender: false,
    callback: () => {},
    width: null,
    height: null,
  },
  navigation: {
    visible: false,
    rendered: true,
    expanded: [],
  },
  message_center: {
    visible: false,
  },
  support_center: {
    visible: false,
  },
  sub_navigation: {
    visible: false,
  },
  account_switch: {
    visible: false,
  },
  contextual_menu: {
    visible: false,
    id: null,
  },
  table_contextual_menu: {
    visible: false,
    id: null,
  },
  drawer: {
    id: AcUUID(),
    visible: false,
    children: null,
  },
  breadcrumbs: {
    items: [],
  },
  new_window: {
    body: null,
    options: null,
  },
};

let _delay = null;
let _target = null;

export class UiStore {
  constructor(store) {
    makeObservable(this);

    this.store = store;
  }

  @observable
  navigation = _default.navigation;

  @observable
  message_center = _default.message_center;

  @observable
  support_center = _default.support_center;

  @observable
  sub_navigation = _default.sub_navigation;

  @observable
  account_switch = _default.account_switch;

  @observable
  contextual_menu = _default.contextual_menu;

  @observable
  table_contextual_menu = _default.table_contextual_menu;

  @observable
  drawer = _default.drawer;

  @observable
  breadcrumbs = _default.breadcrumbs;

  @observable
  modal = _default.modal;

  @observable
  new_window = _default.new_window;

  @computed
  get current_modal() {
    return this.modal;
  }

  @computed
  get current_new_window() {
    return this.new_window;
  }

  @computed
  get current_navigation() {
    return this.navigation;
  }

  @computed
  get current_message_center() {
    return this.message_center;
  }
  @computed
  get current_support_center() {
    return this.support_center;
  }
  @computed
  get current_sub_navigation() {
    return this.sub_navigation;
  }

  @computed
  get current_account_switch() {
    return this.account_switch;
  }

  @computed
  get current_contextual_menu() {
    return this.contextual_menu;
  }

  @computed
  get current_table_contextual_menu() {
    return this.table_contextual_menu;
  }

  @computed
  get current_drawer() {
    return this.drawer;
  }

  @computed
  get current_breadcrumbs() {
    return this.breadcrumbs && this.breadcrumbs.items;
  }

  @action
  multi_select = async (options = {}) => {
    await this.reset(KEYS.MODAL);
    return await this.set(KEYS.MODAL, {
      id: AcUUID(),
      title: TITLES.MULTI_SELECT,
      tag: KEYS.MULTI_SELECT,
      props: { ...options, initialSelection: options?.initialSelection || [] },
      closeable: true,
      visible: true,
      initialSelection: options?.initialSelection || [],
      ...options,
    });
  };

  @action
  confirm = async (options = {}) => {
    await this.reset(KEYS.MODAL);
    return await this.set(KEYS.MODAL, {
      id: AcUUID(),
      title: TITLES.DELETE_CONFIRM,
      tag: KEYS.CONFIRM,
      props: options,
      closeable: false,
      visible: true,
      centered: true,
      ...options,
    });
  };

  @action
  display_linked_to = async (options = {}) => {
    await this.reset(KEYS.MODAL);

    let _options = Object.assign({}, options);

    if (options.title)
      options.title = `<i class="ac-icon ac-icon--alert-circle-outline ac-icon--error"></i> ${options.title}`;
    else
      options.title = `<i class="ac-icon ac-icon--alert-circle-outline ac-icon--error"></i> ${TITLES.FAILED_TO_DELETE_RESOURCE}`;

    return await this.set(KEYS.MODAL, {
      id: AcUUID(),
      tag: KEYS.RESOURCE_LINKED_TO,
      props: options,
      closeable: true,
      visible: true,
      centered: false,
      callback: async () =>
        await this.setValue(KEYS.MODAL, KEYS.VISIBLE, false),
      ...options,
    });
  };

  @action
  setModalBody = async (body) => {
    this.modal.body = body;
  };

  @action
  set = (target, value, initial = true) => {
    return new Promise((resolve) => {
      if (!AcIsSet(target)) return;
      if (AcIsUndefined(this[target])) return;
      if (AcIsUndefined(value)) return;

      const initial = { ..._default[target] };

      if (AcIsSet(initial)) {
        runInAction(() => {
          const newInstance = {
            ...initial,
            ...value,
          };
          set(this[target], newInstance);
        });
      } else {
        runInAction(() => {
          set(this[target], value);
        });
      }

      resolve();
    });
  };

  @action
  setValue = (target, property, value) => {
    return new Promise((resolve, reject) => {
      if (_delay && _target === target) clearTimeout(_delay);

      if (!AcIsSet(target)) {
        reject(`[store][ui] action.setValue: 'target' is not defined`);
        return;
      } else if (!AcIsSet(property)) {
        reject(`[store][ui] action.setValue: 'property' is not defined`);
        return;
      } else if (AcIsUndefined(this[target])) {
        reject(
          `[store][ui] action.setValue: target '${target}' is not an ui element`
        );
        return;
      } else if (AcIsUndefined(this[target][property])) {
        reject(
          `[store][ui] action.setValue: property '${property}' is not part of target ${target}`
        );
        return;
      } else if (AcIsUndefined(value)) {
        reject(`[store][ui] action.setValue: value is not defined`);
        return;
      }

      if (
        target === KEYS.MESSAGE_CENTER &&
        property === KEYS.VISIBLE &&
        value === true
      ) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            runInAction(() => {
              this.support_center.visible = false;
              this.sub_navigation.visible = false;
              this.account_switch.visible = false;
            });
          });
        }, 1000 / 60); // attempt to keep the fps on 60
      } else if (
        target === KEYS.SUPPORT_CENTER &&
        property === KEYS.VISIBLE &&
        value === true
      ) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            runInAction(() => {
              this.message_center.visible = false;
              this.sub_navigation.visible = false;
              this.account_switch.visible = false;
            });
          });
        }, 1000 / 60); // attempt to keep the fps on 60
      } else if (
        target === KEYS.SUB_NAVIGATION &&
        property === KEYS.VISIBLE &&
        value === true
      ) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            runInAction(() => {
              this.support_center.visible = false;
              this.message_center.visible = false;
              this.account_switch.visible = false;
            });
          });
        }, 1000 / 60); // attempt to keep the fps on 60
      } else if (
        target === KEYS.ACCOUNT_SWITCH &&
        property === KEYS.VISIBLE &&
        value === true
      ) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            runInAction(() => {
              this.support_center.visible = false;
              this.message_center.visible = false;
              this.sub_navigation.visible = false;
            });
          });
        }, 1000 / 60); // attempt to keep the fps on 60
      }

      setTimeout(() => {
        requestAnimationFrame(() => {
          runInAction(() => {
            this[target][property] = value;
          });
        });
      }, 1000 / 60); // attempt to keep the fps on 60

      resolve();
    });
  };

  @action
  reset = (target) => {
    return new Promise((resolve, reject) => {
      if (AcIsUndefined(target)) return;
      if (AcIsUndefined(this[target])) return;

      this[target] = _default[target];
      resolve();
    });
  };
}

export default UiStore;
